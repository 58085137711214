// Burger Menu parts
/*
/* (---) top    -> &::before
/* [---] middle -> &
/* (---) bottom -> &::after
*/

// Calc Width Function
@function calc-TriggerTextWidth($value1, $value2) {
    @return $value1 * $value2;
}

// Burger Menu
@mixin burger($width: 44px, $height: 44px, $border: 1px solid $gray-border, $border-radius: $border-radius-small, $background: $gray-background, $padding-x: 4px, $padding-y: 4px, $bar-width: 14px, $bar-height: 2px, $gutter: 2px, $color: #000, $bar-border-radius: $border-radius-small, $transition-duration: .3s) {

    //set global variables
    $burger-height: $bar-height !global;
    $burger-gutter: $gutter !global;

    //reset button styles
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;

    //set burger menu styles
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: $width;
    min-height: $height;
    padding: $padding-y $padding-x;
    background: $background;
    border: $border;
    user-select: none;

    @if $border-radius != 0 {
        border-radius: $border-radius;
    }

    span:first-of-type {
        position: relative;
        display: block;
        margin-top: $bar-height + $gutter;
        margin-bottom: $bar-height + $gutter;
        user-select: none;
        // 1. Fixes jagged edges in Firefox, see issue #10.
        &, &::before, &::after {
            display: block;
            width: 100%;
            max-width: $bar-width;
            height: $bar-height;
            background-color: $color;
            outline: 1px solid transparent; // 1
            @if $bar-border-radius != 0 {
                border-radius: $bar-border-radius;
            }
            transition-property: background-color, transform;
            transition-duration: $transition-duration;
        }

        &::before,
        &::after {
            position: absolute;
            content: "";
        }

        &::before {
            top: -($bar-height + $gutter);
        }

        &::after {
            top: $bar-height + $gutter;
        }
    }

    //text span
    span.triggerText {
        position: relative;
        display: block;
        margin-top: $gutter;
        text-align: center;
        font-size: em(12px);
        font-weight: $bold-text-font-weight;
        line-height: 1em;
        text-transform: uppercase;

        &:empty {
            display: none;
        }
    }
}


// Select parts of the burger
@mixin burger-parts {
    span:first-of-type {
        &, &::before, &::after {
            @content;
        }
    }
}

@mixin burger-top {
    span:first-of-type {
        &::before {
            @content;
        }
    }
}

@mixin burger-middle {
    span:first-of-type {
        & {
            @content;
        }
    }
}

@mixin burger-bottom {
    span:first-of-type {
        &::after {
            @content;
        }
    }
}

// Burger to Cross animation
@mixin burger-to-cross($color: auto) {
    span:first-of-type {
        & {
            background-color: transparent!important;
        }
        @if ($color != auto) {
            &::before, &::after {
                background-color: $color;
            }
        }
        &::before {
            transform: translateY($burger-gutter + $burger-height) rotate(45deg);
        }
        &::after {
            transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
        }
    }
}