#translateContainer {
	flex-shrink: 0;
	position: absolute;
	width: 65px;
	top: 20px;
	right: 110px;

	@media (min-width: $screen-md) {
		position: relative;
		width: auto;
		max-width: 122px;
		top: 0;
		right: 0;
	}

	.goog-te-gadget {
		color: inherit;
	}

	.goog-te-gadget-simple {
		display: block;
		padding: 0;
		font-size: inherit;
		background: transparent;
		border: 0;
	}

	.goog-te-menu-value {
		margin: 0;
		padding: 0;
		color: $topnav-link-color;

		span {
			@media (min-width: $screen-md) {
				font-family: $font-family-base;
				color: $topnav-link-color;
				font-size: $topnav-font-size;
				line-height: $topnav-line-height;
				font-weight: $topnav-font-weight;
				text-decoration: $topnav-link-decoration;
			}
		}

		&:hover span,
		&:active span,
		&:focus span {
			text-decoration: $topnav-link-hover-decoration;
		}
	}

	.goog-te-gadget-simple > img,
	.goog-te-menu-value img,
	.goog-te-menu-value span {
		display: none;
	}

	.goog-te-menu-value span:first-child {
		display: inline-block;
	}

	.languageSelectionWrapper{

		@media (min-width: $screen-md) {
			display: flex;
			padding-left: 19px;
			border-left: solid 1px rgba(20, 20, 20, 0.35);
		}
	}

	.languageSelection{
		padding-right: 10px;
		display: inline-block;
		font-size: em(15px);
		color: rgba(20, 20, 20, 0.35);
		font-weight: bold;
		line-height: 20px;
		cursor: pointer;

		&.current{
			color: rgba(20, 20, 20, 1);
		}
	}



}


// Adjust content and header so translate top bar doesn't cover anything
.skiptranslate {
	&:not([style="display: none;"]) {

		~ div #uber,
		~ #uber {

			header {
				top: 38px !important;
			}
		}
	}
}