.contrastSwitcher {
    padding-left: 32px !important;
    display: inline-block;
    color: #fff;
    background: none;
    font-weight: bold;
    padding: 5px 10px;
    border: 0;
    position: relative;

    &:hover, &:focus {
        text-decoration: underline;
    }

    &:before {
        @include icomoon();
        content: $icon-contrast;
        position: absolute;
        font-size: em(15px);
        color: #a5a5a5;
        display: flex;
        align-items: center;
        top: 8px;
        left: 8px;
        pointer-events: none;
        transform-origin: center center;
        transition: transform 0.3s ease-in;
    }

    @media (min-width: $screen-md) {
        &:before {
            font-size: em(16px);
        }
    }
    .contrast-mode & {
        &:before {
            color: #fff;
            transform: rotate(180deg);
        }
    }
}