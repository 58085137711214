@import 'icomoon/variables';

/*********************
BREAKPOINTS
// USAGE:
//
// MIXIN:	header {
//				@include bp-medium {
//					background: blue;
//				}
//			}

// EXTEND:	header {
//				@extend %clearfix;
//			}
*********************/

@mixin bp($point) {
  @if $point==x-large {
    @media screen and (min-width: $screen-xl) {
      @content ;
    }
  }

  @if $point==large {
    @media screen and (max-width: $screen-lg) {
      @content ;
    }
  }

  @else if $point==medium {
    @media screen and (max-width: $screen-md) {
      @content ;
    }
  }

  @else if $point==small {
    @media screen and (max-width: $screen-sm) {
      @content ;
    }
  }

  @else if $point==x-small {
    @media screen and (max-width: $screen-xs) {
      @content ;
    }
  }

  @else if $point==ipad {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      @content ;
    }
  }

  @else if $point==ipad-portrait {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
      @content ;
    }
  }

  @else if $point==ipad-landscape {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
      @content ;
    }
  }
}

@mixin bp-max($point) {
  @media screen and (max-width: $point) {
    @content ;
  }
}

@mixin bp-min($point) {
  @media screen and (min-width: $point) {
    @content ;
  }
}

@mixin bp-hd() {
  @media screen and (-webkit-min-device-pixel-ratio: 2) {
    @content ;
  }
}

// SERVE SCALED IMAGES BASED ON PIXEL DENSITY

@mixin retina($image, $width, $height) {

  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}

// RETINA USAGE

// div.brand {
//    background: url("../img/brand.png") no-repeat;
//    @include retina("../img/brand.png", 100px, 45px);
//  }

@mixin bucketColor($property) {
  #{$property}: $brand-one;

  .navTab01 & {
    #{$property}: $brand-one;
  }

  .navTab02 & {
    #{$property}: $brand-two;
  }

  .navTab03 & {
    #{$property}: $brand-one;
  }

  .navTab04 & {
    #{$property}: $brand-two;
  }

  .navTab05 & {
    #{$property}: $brand-one;
  }
}

// center items vertically
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}

// CLEARFIX
%clearFix {
  *zoom: 1;

  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

// RESET LIST
%resetList {
  margin: 0;
  padding: 0;
  position: relative;
  list-style-type: none;

  /* remove bullets */
  &:before {
    content: "\200B";
    /* add zero-width space */
    position: absolute;
    /* addition */
  }
}

%text-break {
  word-break: break-word;
  overflow-wrap: break-word;
}

%text-truncate {
  // display block or inline-block also required
  text-overflow: ellipsis;
  overflow: hidden;
}

// CENTER BLOCK
%centerBlock {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

%defaultFocus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

@mixin icomoon() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin defaultButton($bg: $gray-lighter, $color: color-contrast($bg), $border-color: transparent) {
  clear: both;
  display: inline-block;
  margin: 2px 0;
  padding: .6475em 1.17647059em;
  min-width: 188px;
  max-width: 100%;
  font-size: em(17px);
  font-weight: 500 !important;
  line-height: $headings-line-height;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  color: $color !important;
  background-color: $bg;
  border: solid 2px $border-color;
  border-radius: 0;
  overflow: hidden;
  transition: background-color $transition-base;

  img,
  svg {
    margin-right: 0.375em;
    margin-left: 0.375em;
    max-width: 1.0625em;
    max-height: 1.0625em;
  }

  svg * {
    fill: $color;
  }

  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: white;
    border-color: currentColor;
    cursor: pointer;

    @if (color-contrast-ratio(white, $bg) > 4.5) {
      color: $bg !important;
    }

    @else {
      color: $gray-darker !important;
    }
  }

  &:first-child {
    margin-left: 0;
  }
}

@mixin emphasis($bg: $gray-background, $border-color: transparent, $icon: (null, $border-color), $color: color-contrast($bg)) {
  position: relative;
  display: block;
  margin: 15px 0;
  padding: 20px 24px;
  color: $color;
  background-color: $bg;
  border: 1px solid $border-color;
  border-radius: 0;
  overflow: hidden;

  @if (nth($icon, 1)) {
    padding-left: 68px;

    &::before {
      @include icomoon();
      content: nth($icon, 1);
      display: inline-block;
      position: absolute;
      top: 20px;
      left: 24px;
      font-size: em(24px);
      color: nth($icon, 2);
    }
  }

  a {
    text-decoration: underline;
    color: $color;

    &:focus,
    &:hover {
      color: $color;
    }

    &:focus {
      outline: 1px dotted currentColor;
    }
  }
}

// Import Mixins
@import 'mixins/colourContrastMixin';
@import 'mixins/burgerMenuMixin';
@import 'mixins/socialIconsMixin';