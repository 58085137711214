$topBar-bg: rgba(255, 255, 255, 0) !default;
$topBar-color: color-contrast($topBar-bg, $colors: (white, #141414)) !default;

#topNavContainer {
	@media (min-width: $screen-md) {
		display: flex;
		justify-content: flex-end;
		align-content: center;
		width: 100%;
		height: auto;
		margin-bottom: 27px;
		color: $topBar-color;
		background-color: $topBar-bg;
		max-width: 100%;
		transition: height 0.3s ease;

		.scrolling & {
			height: 0;
		}

		> * {
			display: inline-flex;
			align-items: center;
			min-width: 1px;
			height: 100%;
			overflow: hidden;

			+ * {
				margin-left: 21px;
			}
		}

		script {
			margin: 0;
		}
	}
}

#topNav {
	display: none;

	@media (min-width: $screen-md) {
		display: inline-flex;

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				display: inline-block;
				max-width: 200px;
				padding: 0 15px;
				text-overflow: ellipsis;
				overflow: hidden;

				&:last-of-type {
					padding-right: 0;
				}
			}
		}

		a {
			font-size: $topnav-font-size;
			line-height: $topnav-line-height;
			font-weight: $topnav-font-weight;
			text-decoration: $topnav-link-decoration;
			color: $topnav-link-color;

			&:active,
			&:focus,
			&:hover {
				text-decoration: $topnav-link-hover-decoration;
			}
		}
	}
}
