$header-bg: white !default;
$header-color: color-contrast($header-bg, $colors: (white, $text-color)) !default;
$header-left-width: 256px !default;

header {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
	width: 100%;
	color: $header-color;
	background-color: $header-bg;
	z-index: 10;
	background-image: url('../images/structure/header-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top right;

	@media (max-width: $screen-md - 1) {
		transform: none !important; // override JS embedded style
	}

	@media (min-width: $screen-md) {
		padding-right: 0;
		padding-left: 0;
		transition: all $transition-base;

		.scrolling & {
			position: sticky;
			top: 0;
			box-shadow: 0 3px 6px rgba(#000, 0.20);
		}

		.scrollingDownward & {
			transition-delay: 0.1s;
		}
	}
}

#headerInner {
	display: flex;
	flex-wrap: wrap;

	&::before,
	&::after {
		display: none;
	}

	@media (min-width: $screen-md) {
		max-width: 100%;
	}
}

#headerLeft {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	width: 100%;

	@media (max-width: $screen-md - 1) {
		justify-content: space-between;
	}


	@media (min-width: $screen-md) {
		padding-right: 1rem;
		width: $header-left-width;
	}
}

#headerRight {
	width: 100%;

	@media (min-width: $screen-md) {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		max-width: calc(100% - #{$header-left-width});
		padding-top: 21px;
	}
}

#logo {
	transition: margin .3s ease;
	
	@media (max-width: $screen-md - 1 ) {
		padding: .5em;
	}

	a {
		display: inline-block;
		outline-offset: 2px;

		@media (max-width: $screen-md - 1 ) {
			max-width: 50%;
		}

		.printLogo {
			display: none;
		}

		img {
			width: 100%;
			height: auto;
		}
	}
}