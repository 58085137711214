.searchContainer {
    width: 304px;
    margin-bottom: 20px;

    @media (max-width: $screen-md - 1) {
        padding: 0.375em;
        margin: auto;
    }



}

.search {
    width: 100%;
    background: #fff;
    border: solid 2px $search-border-color;
    height: 56px;
    overflow: hidden; 
    
}

.gsc-input {
    font-size: 1em !important;
    padding: 2px;
}

input.gsc-input {
    &:active,
	&:focus
	{
        outline: 2px solid -webkit-focus-ring-color !important;
        outline-offset: -2px;
    }
}

.gsc-input-box {
    //padding: 18px 19px !important;
    vertical-align: middle;
    height: 52px !important;
    padding-top: 0 !important;


}


#gsc-iw-id1 {
    border: none;
}

.gsc-search-button-v2 {
    background-color: #FFF !important;
    border: none !important;

    svg {
        display: none;
    }
}

.gsc-search-button-v2 svg {
    fill: #141414 !important;
}

#gs_cb50{
    display: none;
}



/*==================
  Cloud Search
====================*/



.gsc-control-searchbox-only form.gsc-search-box table.gsc-search-box {
    position: relative;
}

.gsc-control-searchbox-only form.gsc-search-box table.gsc-search-box td.gsc-input {
    padding: 0;
    vertical-align: middle;
    height: 52px;
}

.gsib_a {
    padding: 0 !important;
}

.gsc-control-searchbox-only form.gsc-search-box input.gsc-input {
    color: $search-text-color;
    border: none;
    border-right: none;
    height: 52px !important;
    width: 100%;
    box-sizing: border-box;
    padding: 19px 19px !important;
    padding-bottom: 18px !important;
}

.gsc-control-searchbox-only form.gsc-search-box input.gsc-input::placeholder {
    color: $search-text-color;
    opacity: 1;
}

.gsc-control-searchbox-only form.gsc-search-box input.gsc-search-button,
button.gsc-search-button {
    padding: 0;
    padding-right: 20px;

    &::after {
        @include icomoon();
        content: $icon-search;
        display: inline-block;
        font-size: 1rem;
        pointer-events: none;
        line-height: 1.2;
        color: #000;
        overflow: hidden;
    }

}

.gsc-control-searchbox-only form.gsc-search-box td.gsc-search-button {
    padding: 0;

}

.gsc-control-searchbox-only form.gsc-search-box td.gsc-clear-button {
    width: auto;
    position: absolute;
    right: 30px;
    top: 5px;

}

div.gsc-clear-button {
    display: none !important;
}


/*GOOGLE SEARCH RESULTS*/
.gsc-control-cse {
    margin-top: 5px;
}

.gsc-control-cse .gsc-control-wrapper-cse .gsc-control-cse .gsc-table-result {
    font-family: inherit;
}

.gsc-control-cse .gsc-control-wrapper-cse .gs-result .gs-title,
.gsc-control-cse .gsc-control-wrapper-cse .gs-result .gs-title * {
    color: #15C !important;
}

.gsc-control-cse .gsc-control-wrapper-cse .gs-result a.gs-visibleUrl,
.gsc-control-cse .gsc-control-wrapper-cse .gs-result .gs-visibleUrl {
    color: #093 !important;
}

.gsc-control-cse .gsc-control-wrapper-cse .gsc-webResult.gsc-result:hover,
.gsc-control-cse .gsc-control-wrapper-cse .gsc-imageResult:hover {
    border-color: #eee;
    background-color: #f8f8f8;
}

.gsc-control-cse .gsc-control-wrapper-cse .gsc-results .gsc-cursor-box .gsc-cursor-page {
    background-color: #eee;
    color: #333;
    padding: 7px 12px;
    text-decoration: none;
}

.gsc-control-cse .gsc-control-wrapper-cse .gsc-results .gsc-cursor-box .gsc-cursor-page:hover {
    color: #fff;
    background-color: #15C;
}

.gsc-control-cse .gsc-control-wrapper-cse .gsc-results .gsc-cursor-box .gsc-cursor-current-page {
    text-shadow: none;
    color: #fff;
    background-color: #15C;
}

.gsc-control-cse .gsc-control-wrapper-cse .gsc-option-menu-container.gsc-inline-block .gsc-selected-option-container.gsc-inline-block {
    width: 100px !important;
}