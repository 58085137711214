.prevent-scrolling {
	max-height: 100vh;
	overflow: hidden;
}
%idLikeToSlideoutIconBtn {
	flex-shrink: 0;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 44px;
	min-height: 44px;
	text-align: center;
	background-color: transparent;
	border: 0;

	&:focus,
	&:hover {
		color: color-contrast($brand-one);
		background-color: $brand-two;
	}
}

#idLikeTo {
	@media (min-width: $screen-md) {
		display: none;
		flex-shrink: 0;
		display: flex;
		align-items: center;
	}

	@media (min-width: $screen-lg) {
		margin-left: 60px;
	}

	button {
		padding: 9px 16px 10px 14px;
		font-size: $topnav-font-size;
		white-space: nowrap;
		font-family: "Open Sans", sans-serif;
		font-weight: 600 !important;
		line-height: 20px;
		text-transform: capitalize;
		border: 0;
		width: 140px;

		@media (max-width: $screen-md - 1) {
			text-align: left;
			background-color: $brand-one;
			border-radius: 0;
		}

		&::after {
			@include icomoon();
			content: $icon-feather-menu;
			display: inline-block;
			margin-left: 15px;
			// transform: rotate(90deg);
			font-size: 0.9em;
			pointer-events: none;
			width: 12.3px;
		}
	}
}

.idLikeToMenuToggle {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 44px;
	padding: 0;
	margin: 0;
	text-align: left;
	font-weight: 700;
	background-color: transparent;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.47' height='19.471' viewBox='0 0 19.47 19.471'%3E%3Cg transform='translate(9.947 -7.585) rotate(45)'%3E%3Cpath d='M.884,12.884-.884,11.116l12-12L12.884.884Z' transform='translate(6.098 6.397)' fill='%23236b84'/%3E%3Cpath d='M11.116,12.884l-12-12L.884-.884l12,12Z' transform='translate(6.098 6.397)' fill='%23236b84'/%3E%3C/g%3E%3C/svg%3E");
	background-position: right center;
	background-size: 24px;
	background-repeat: no-repeat;
	border: 0;
	transition: all 0.3s ease;

	&[aria-expanded="true"] {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.47' height='19.471' viewBox='0 0 19.47 19.471'%3E%3Cg transform='translate(9.947 -7.585) rotate(45)'%3E%3Cpath d='M.884,12.884-.884,11.116l12-12L12.884.884Z' transform='translate(6.098 6.397)' fill='%23236b84'/%3E%3C/g%3E%3C/svg%3E");

		+ ul {
			display: block !important;
		}
	}
}

#idLikeToSlideout {
	display: none;
	position: fixed;
	//padding: 15px;
	top: 0;
	right: 0;
	bottom: 0;
	transform: translateX(100%);
	z-index: 8001;
	width: 420px;
	max-width: 100%;
	height: 100vh;
	color: $brand-one;
	background-color: #ffffff;
	transition: transform 400ms ease;

	@media (min-width: $screen-md) {
		//padding: 48px 30px 48px 40px;
	}

	a {
		color: inherit;
	}

	ul {
		margin: 0;
		padding-left: 29px;
		padding-right: 38px;
		line-height: $headings-line-height;
		list-style: none;
	}

	> ul {
		> li {
			display: flex;
			flex-wrap: wrap;
			border-top: 1px solid #dddddd;

			> a,
			> button {
				flex-grow: 1;
				display: block;
				padding-top: 0;
				padding-bottom: 0;
				width: calc(100% - 44px);
				font-size: em(20px);
				color: #000;
				font-weight: bold;
				line-height: 24px;
			}

			> button {
				padding: 1em 0;

				@media (min-width: $screen-md) {
					padding: 28px 10px;
				}
			}

			&:first-of-type {
				border-top: none;
			}
		}

		ul {
			display: none;
			margin-bottom: 0;
			font-size: em(16px);
			padding-left: 10px;

			li {
				a {
					display: block;
					padding-bottom: 23px;
					color: #167482;
					font-size: 18px;
					font-weight: bold;
					padding-left: 0;
					line-height: 24px;
				}

				&:last-of-type {
					padding-bottom: 0;
				}
			}
		}
	}

	&.is-shown {
		display: block;
		transform: translateX(0);

		+ #idLikeToSlideoutOverlay {
			display: block;
		}
	}
}

#idLikeToSlideoutClose {
	background-color: rgba(255, 255, 255, 0);
	border: none;
	position: absolute;
	top: 59px;
	right: 350px;

	&::after {
		@include icomoon();
		content: $icon-close;
		display: inline-block;
		font-size: 1.7em;
		pointer-events: none;

		color: #000;
		overflow: hidden;
	}

	@media (min-width: $screen-xs) {
		top: 59px;
		right: 430px;

		&::after {
			color: #fff;
		}
	}

	@media (min-width: $screen-lg) {
		top: 45px;
		right: 449px;
		z-index: 200;
		display: inline-block;
		background-color: transparent;
		border: none;
		width: 60px;
		height: 60px;
		border-radius: 50%;

		&::after {
			@include icomoon();
			content: $icon-close;
			display: inline-block;
			font-size: 1.7em;
			pointer-events: none;

			color: #fff;
			overflow: hidden;
		}

		&:hover,
		&:focus {
			background-color: rgba(0, 0, 0, 0.445);
			transition: 1s;
		}
	}
}

#idLikeToSlideoutOverlay {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 8000;
	background: radial-gradient(#414042, #231f20);
	opacity: 0.8;
}

#idLikeToSlideoutTitle {
	@extend %text-truncate;
	width: 100%;
	margin-top: 0;
	margin-bottom: 0;
	color: #000;
	height: 132px;
	padding: 48.5px 80px;
	font-size: em(34px);
	line-height: 46px;

	&::after {
		@include icomoon();
		content: $icon-feather-menu;
		display: inline-block;
		margin-left: 29%;
		font-size: 0.75em;
		pointer-events: none;
		width: 12.3px;
	}
	@media (min-width: $screen-xs) {
		padding: 48.5px 36px;

		&::after {
			margin-left: 156px;
		}
	}
}

#idLikeToSlideoutTitleBar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $brand-one;
	margin-bottom: 30px;
}
