//  ======================================================
//  NEWSTICKER.SCSS
//  Styles for news ticker
//  ======================================================

.tickerContentOuter {
	position: relative;
	padding-top: 15px;
	padding-bottom: 15px;
	background: $gray-background;
}

.ticker-content {
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
	position: relative;
	width: 100%;
	max-width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #000;
	overflow: hidden;

	@media (min-width: $screen-md) {
		flex-wrap: nowrap;
	}

	> * {
		min-width: 1px;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	li,
	ul {
		@extend %resetList;
	}

	li {
		a {
			color: $link-color;
			padding-top: 5px;
			margin-top: 5px;
			border-top: 1px solid $gray-border;
			padding-bottom: 5px;
			text-decoration: none;
			display: block;
			text-overflow: ellipsis;
			overflow: hidden;
			font-weight: 500;
			font-size: $font-size-small;

			@media (min-width: $screen-md) {
				padding: 0 0 0 10px;
				margin: 0px;
				border: none;
				font-size: 1em;
			}

			&:focus,
			&:hover {
				text-decoration: underline;
			}
		}
	}

	ul {
		flex-grow: 1;
	}

	.newsTickerTitle {
		flex-shrink: 0;
		display: block;
		max-width: 110px;
		font-weight: normal;

		@media (min-width: $screen-md) {
			display: inline-block;
		}
	}
}

#newsPause {
	flex-shrink: 0;
	display: inline-block;
	margin-left: .5rem;
	height: 25px;
	line-height: 1em;

	a {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 25px;
		height: 25px;
		overflow: hidden;

		&:before {
			@include icomoon();
			content: $icon-pause;
			font-size: em(12px);
			pointer-events: none;
		}

		&:active,
		&:focus,
		&:hover {
			text-decoration: none;
			background-color: lighten($brand-one, 75%);
		}
	}

	&.selected a {
		&:before {
			content: $icon-play;
			font-size: em(16px);
		}
	}
}

#newsViewAll {
	flex-shrink: 0;
	max-width: 80px;
	font-size: $font-size-small;

	@media (min-width: $screen-md) {
		margin-left: .5rem;
		font-size: 1em;
	}
}