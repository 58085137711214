$footer-bg: #fff !default;
$footer-font-size: $font-size-small !default;
$footer-line-height: $line-height-base !default;
$footer-text-color: #141414 !default;
$footer-link-color: #2E738B !default;
$footer-link-decoration: none !default;
$footer-link-hover-decoration: underline !default;
$footer-left-max-width: 100% !default;

@import 'footerNav';
@import 'footerNavMega';

#footer {
	width: 100%;
	background: $footer-bg;
	color: $footer-text-color;
	font-size: $footer-font-size;
	line-height: $footer-line-height;
	padding: 0;
	z-index: 1;
	position: relative;

	.interior & {
		border-top: solid 1px #DDDDDD;
	}
	p{
		max-width: 100%;
	}
	a {
		color: $footer-link-color;
		text-decoration: $footer-link-decoration;

		&:hover {
			text-decoration: $footer-link-hover-decoration;
		}

		&[href^=tel] {
			color: $footer-link-color;
		}
	}
}

.footerText {
	> * {
		text-overflow: ellipsis;
		overflow: hidden;
	}

	br{
		display: inline;
	}

	@media (min-width: $screen-md) {
		br{
			display: none;
		}
	}
}

/*==================
Footer Top
====================*/

.footerTop {
	padding: 44px 0 17px;
	width: 100%;
	text-align: center;
	border-bottom: solid 1px #DDDDDD;

	@media (min-width: $screen-md) {
		display: flex;
		padding-top: 100px;
		text-align: left;
	}

	p{
		margin: 33px 0 15px 0;
		color: #141414;
	}
}

.footerTopLeft {
	@media (min-width: $screen-md) {
		flex-shrink: 0;
		width: $footer-left-max-width;
		text-align: center;
	}

	.footerText p a{
		padding-right: 15px;
	}
}

/*==================
Footer Logo
====================*/

.footerLogo {
	display: block;
	margin-right: auto;
	margin-left: auto;
	width: $footer-left-max-width;
	max-width: 100%;



	img {
		max-width: 230px;
		height: auto;
	}

	p {
		margin-top: 0;
		margin-bottom: 0;
	}



}

/*==================
Footer Bottom
====================*/

.footerBottom {
	position: relative;
	border-top: 1px solid rgba(#fff, .25);
	width: 100%;
	padding: 20px 0 84px;
	min-height: 80px;
	text-align: center;
	clear: both;
}