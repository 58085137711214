//  ======================================================
//  DEVTWEAKS.SCSS
//  **** Last Resort ***
//  ======================================================

/* EDITOR STYLES */
.boxImage {
    min-height: 120px;
}

/* Temp styles for calendar and news*/
.icrtcalendarfeed .viewMore, .icrtnewsfeed .viewMore{
	display: inline-block;
	margin-bottom: 30px;
	color: #fff;
	background-color: #004B8D;
	padding: 10px;
}

.icrtcalendarfeed .calendarItem{
	margin-bottom: 10px;
	width: 100%;
	min-height: 50px;
}

.icrtcalendarfeed .calendarItem .date {
    color: #fff;
    text-align: center;
    width: 50px;
    height: 50px;
    background-color: #004B8D;
    padding-top: 9px;
    border-radius: 2px;
    font-size: 0.94471em;
    line-height: 1.05882em;
    font-weight: 500;
    text-transform: uppercase;
	font-weight: bold;
}
.icrtcalendarfeed .calendarItemLeft{
	float: left;
	padding-right: 10px;
}

.icrtnewsfeed p{
	margin-top: 0px;
}

.icrtnewsfeed .newsTitle{
	font-size: em(20px);
}