//  ======================================================
//  CONTACTAUTOCOMPLETE.SCSS
//  styles for Contact module
//  ======================================================

#contactMainContainer {
	background: $gray-background;
	color: #000;
	float: left;
    width: 100%;
	margin: 0 0;
	padding: 22px 25px;
	position: relative;
	overflow: hidden;
    z-index: 1250;
    text-align: left;
	border-top: solid em(5.23px) #414042;
	margin-bottom: 30px;

    .contactContainer {
		overflow: hidden;
	}

	.contactHeader {
		padding: 0;
		float: left;
		width: 100%;
	}

	.contactHeaderTitle {
		display: block;
		overflow: hidden;
		border-bottom: 1px solid #c1c1c1;
		padding: 20px 0 16px;

		img{
			width: 22px;
			height: 22px;
			margin-right: 12px;
		}

		p {
			font-size: em(28px);
			line-height: 1.375em;
			font-weight: bold;
			margin: 0 !important;
		}
	}

	.contactContainer .contactBody {
		float: left;
		width: 100%;
		font-size: $font-size-small;
		padding: 15px 0;

		@media (min-width: $screen-sm) {
			font-size: 1em;
		}

		p {
			margin: 0 0 10px 0;
			color: #000;
			text-indent:0;
		}

		p span{
			position: relative;
			top: 14px;
		}

		strong{ font-weight: 600;}
		a{ font-weight: bold;}
	}

	.contactContainer .autoCompleteContainer {
		text-align: left;
		padding: 10px 20px;
		width: 100%;
	}
}

#contactMainContainer_AutoComplete {
    width: 100px;
}
