#browseAloudContainer {
	flex-shrink: 0;

	@media (max-width: $screen-md - 1) {
		width: 100%;
	}

	@media (min-width: $screen-md) {
		max-width: 100px;
	}

	a,
	button {
		@media (min-width: $screen-md) {
			padding: 0;
			width: auto;
			font-size: $topnav-font-size;
			font-weight: $topnav-font-weight;
			line-height: $topnav-line-height;
			text-decoration: $topnav-link-decoration;
			color: $topnav-link-color;
			background-color: transparent;
		}

		&:active,
		&:focus,
		&:hover  {
			@media (min-width: $screen-md) {
				text-decoration: $topnav-link-hover-decoration;
			}
		}
	}
}

#_ba__button_link {
	display: flex;
	align-items: center;
	height: 100%;
}

#_ba__link {
	text-overflow: ellipsis;
	overflow: hidden;
}

#__ba_panel {
	max-width: 100%;
}