// FACILITIES MODULE

#facilityResultsContainer {
    .result-header {
        background-color: $brand-one !important;
        color: color-contrast($brand-one) !important;
    }

    .result-header a {
        color: color-contrast($brand-one) !important;
    }
}

.container-box-header {
    background-color: $brand-one !important;
    color: color-contrast($brand-one) !important;
}

// BUSINESS DIRECTORY MODULE

#businessDirectoryWrapper .row.bg-blue {
    background-color: $brand-one;
    color: color-contrast($brand-one) !important;
}

// FORM BUILDER

#formBuilder {
    min-height: 400px;
}

.formbuilder-uber #uberMain {
    z-index: 9
}

.formbuilder-uber {
    #uberMain,
    #main,
    #contentInt,
    #mainContent,
    .ge-content,
    .fbg-row .column .fbg-row .fbg-col-xs-12 {
        overflow: visible
    }
}

#formBuilder {
    min-height: 400px;
}

// Override modal & cludo search results Headings font to regular text font for readability
.feedbackModal .ffFormContainer,
#pageSubscriptionFormDialog .pageSubscriptionModal #pageSubscriptionForm {
    h1, h2, h3, h4, h5, h6 {
        font-family: $font-family-sans-serif;
        font-weight: 700;
    }
}

// CLUDO

#cludo-search-results .cludo-search-modal-body .search-results .search-results-item a h2,
#cludo-search-results .cludo-search-modal-body .search-results .search-results-item a h3 {
    font-family: $font-family-sans-serif;
    font-weight: 700;
}

// NEWS V3

.blogFeed {
    #blogContentContainer {
        .backToSearchWrapper {
            button,
            .btn {
                color: $link-color;
            }
        }

        .blogItem h2 a,
        .blogItem h3 a {
            color: $link-color;
        }
    }

    #blogNavWrapper .blogNavContainer #subscribe {
        color: color-contrast($brand-one) !important;
        background-color: $brand-one;

        &:active, &:focus, &:hover {
            color: color-contrast($brand-one) !important;
            background-color: $brand-one;
        }

        &::before {
            @include icomoon();
            content: $icon-subscribe;
            display: inline-block;
            width: 13px;
            margin-right: 15px;
            margin-top: 2px;
            color: #000;
            font-size: em(16px);
        }
    }

    svg *:not(.readMore) {
        fill: color-contrast($brand-one) !important;
    }

    #blogSearchContainer {
        #btnSearchFilter {
            color: color-contrast($brand-one);
            background: $brand-one;
            font-size: 1em !important;
        }
    }

    .blogSearch .blogSearchText, select, .date-time input[type=text], .buttonHolder .tags-removeAllBtn {
        font-size: 1em !important;
    }

    #blogPagination .ic-pagination {
        > li {
            > a {
                color: $link-color !important;

                &:focus,
                &:hover {
                    color: color-contrast($link-color) !important;
                    background-color: $link-color !important;
                }
            }

            &.active {
                > a {
                    color: color-contrast($link-color) !important;
                    background-color: $link-color !important;
                }
            }
        }
    }
}

.daterangepicker td.in-range {
    background-color: $gray-background !important;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
    color: color-contrast($brand-one) !important;
    background-color: $brand-one !important;
}

.daterangepicker {
    .drp-buttons {
        .btn-primary {
            color: color-contrast($brand-one) !important;
            background: $brand-one !important;
        }
    }
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
    font-size:1em !important;
    line-height: 1.2em !important;
}

.blogPostCategory {
    &:before {
        background-image: url('../../Modules/NewsModule/images/blog/icon-news-category.svg') !important;
    }
}

.blogPostDate {
    &:before {
        background-image: url('../../Modules/NewsModule/images/blog/icon-news-clock.svg') !important;
    }
}

.blogPostTag {
    &:before {
        background-image: url('../../Modules/NewsModule/images/blog/icon-news-tag.svg') !important;
    }

    #btnBlogSearch {
        background-image: url('../../Modules/NewsModule/images/blog/search-icon.svg') !important;

        .contrast-mode & {
            background-image: url('../../Modules/NewsModule/images/blog/search-icon.svg') !important;
        }
    }
}

#blogSearchContainer {
    select {
        background-image: url('../../Modules/NewsModule/images/blog/icon-chevron.svg') !important;

        .contrast-mode & {
            background-image: url('../../Modules/NewsModule/images/blog/icon-chevron.svg') !important;
        }
    }
}

.blogFeed {
    .blogCommentList {
        .comment {
            .commentDate {
                .postReply {
                    background-image: url('../../Modules/NewsModule/images/blog/icon-reply.svg') !important;

                    &:hover,
                    &:focus {
                        background-image: url('../../Modules/NewsModule/images/blog/icon-reply.svg') !important;
                    }
                    &:disabled {
                        background-image: url('../../Modules/NewsModule/images/blog/icon-reply.svg') !important;
                    }
                }
            }
        }
    }

    #blogSearchContainer {
        .blogSearch {
            #btnBlogSearch {
                background-image: url('../../Modules/NewsModule/images/blog/search-icon.svg') !important;

                .contrast-mode & {
                    background-image: url('../../Modules/NewsModule/images/blog/search-icon.svg') !important;
                }
            }
        }

        .date-time:after {
            background: url('../../Modules/NewsModule/images/blog/small-calendar.svg') !important;
        }
    }
}