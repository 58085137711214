@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?7uimx5') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?7uimx5') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?7uimx5##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youtube-circle {
  &:before {
    content: $icon-youtube-circle; 
  }
}
.icon-twitter-circle {
  &:before {
    content: $icon-twitter-circle; 
  }
}
.icon-facebook-circle {
  &:before {
    content: $icon-facebook-circle; 
  }
}
.icon-feather-menu {
  &:before {
    content: $icon-feather-menu; 
  }
}
.icon-simple-minus {
  &:before {
    content: $icon-simple-minus; 
  }
}
.icon-simple-plus {
  &:before {
    content: $icon-simple-plus; 
  }
}
.icon-hamburger {
  &:before {
    content: $icon-hamburger; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest; 
  }
}
.icon-snapchat {
  &:before {
    content: $icon-snapchat; 
  }
}
.icon-vimeo {
  &:before {
    content: $icon-vimeo; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-snapchat-square {
  &:before {
    content: $icon-snapchat-square; 
  }
}
.icon-tiktok {
  &:before {
    content: $icon-tiktok; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}
.icon-rss-square {
  &:before {
    content: $icon-rss-square; 
  }
}
.icon-rss {
  &:before {
    content: $icon-rss; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-enlarge {
  &:before {
    content: $icon-enlarge; 
  }
}
.icon-shrink {
  &:before {
    content: $icon-shrink; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-contrast {
  &:before {
    content: $icon-contrast; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-chevron-thin {
  &:before {
    content: $icon-chevron-thin; 
  }
}
.icon-chevron-thick {
  &:before {
    content: $icon-chevron-thick; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-arrow {
  &:before {
    content: $icon-arrow; 
  }
}
.icon-bell {
  &:before {
    content: $icon-bell; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-info-circle {
  &:before {
    content: $icon-info-circle; 
  }
}
.icon-phone-ring {
  &:before {
    content: $icon-phone-ring; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-subscribe {
  &:before {
    content: $icon-subscribe; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-text-size-decrease {
  &:before {
    content: $icon-text-size-decrease; 
  }
}
.icon-text-size-default {
  &:before {
    content: $icon-text-size-default; 
  }
}
.icon-text-size-increase {
  &:before {
    content: $icon-text-size-increase; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}

