//  ======================================================
//  INSTAGRAM.SCSS
//  Styles for instagram boxes
//  ======================================================

#instagramIntro {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 40px;
    overflow: hidden;

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: em(40px);
    }

    p {
        line-height: 1.5em;
    }

    a {
        color: $brand-one;
    }
}

#instagramItem .SMDropdown {
    min-height: 0;
    overflow: visible;
}

#instagramFeed {
    margin-top: -16px;
    overflow: hidden;
}

#instagramFeeds {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    min-height: 190px;
    margin-left: 0;
    margin-top: 80px;

    @media (min-width: $screen-md) {
        flex-wrap: nowrap;
        padding-left: 0;
        padding-right: 0;
    }

    &:before,
    &:after {
        content: normal;
    }

    &:nth-child(5) {
        display: none !important;
    }

    .sMItem {
        width: 50%;
        position: relative;
        margin-bottom: 0;
        padding: 0;
        overflow: hidden;

        &:nth-of-type(5) {
            display: none;
        }

        @media (min-width: $screen-xs) {
            flex: 0 1 auto;
        }

        @media (min-width: $screen-md) {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;

            &:nth-of-type(5) {
                display: block;
            }
        }



        @media (min-width: 1600px) {
            padding: 0;
        }


        a {
            width: 100%;
            height: 100%;
            display: inline-block;
            position: relative;

            &:before {
                content: "";
                width: 40px;
                height: 40px;
                display: block;
                background-image: url(../../en/resourcesGeneral/socialicons/Social_Knockout_White/icon-instagram.svg);
                background-size: 40px 40px;
                background-position: center center;
                background-repeat: no-repeat;
                position: absolute;
                top: 14px;
                right: 14px;
                z-index: 1;
            }

            &:hover,
            &:focus {
                img {
                    transform: scale(1.08) rotate(0.1deg);
                }
            }

            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                backface-visibility: hidden;
                transform-style: preserve-3d;
                transform: scale(1.01);
                opacity: 0;
                transition: all 0.3s ease-in-out;

                &.loaded {
                    opacity: 1;
                }
            }
        }
    }

    .sMItem:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

}