//  ======================================================
//  MAINNAV.SCSS
//  styles for main menu & mobile menu
//  ======================================================

$nav-font-size:					em(21px) !default;
$nav-line-height:				em(28px) !default;
$nav-font-weight:				normal !default;
$nav-font-family:				'Open Sans Condensed', sans-serif !default;
$nav-item-text-color:		    black !default;
$nav-item-text-align:		    left !default;
$nav-item-spacing:				1px !default; //gap between items
$nav-item-spacing-color:		transparent !default; //color of divider between items
$nav-item-bg:					transparent !default;
$nav-item-hover-bg:				transparent !default;
$nav-item-hover-color:			#000 !default;

$nav-dropdown-bg:						white !default;
$nav-dropdown-border:					$gray-light !default;
$nav-dropdown-padding:					20px 12px 28px 12px !default;
$nav-dropdown-border-radius:		    3px !default;

$nav-dropdown-col-count:				3 !default; // number of columns in dropdown
$nav-dropdown-col-spacing:				em(30px) !default; // space between columns

$nav-dropdown-item-padding:				13px 0 !default;
$nav-dropdown-item-divider:				$gray-light !default;
$nav-dropdown-item-font-size:			$font-size-base !default;
$nav-dropdown-item-font-weight:			600 !default;
$nav-dropdown-item-text-color:			#236B84 !default;
$nav-dropdown-item-decoration:			none !default;
$nav-dropdown-item-hover-decoration: 	underline !default;
$nav-dropdown-item-hover-bg: 			transparent !default;

$mobile-dropdown-bg:					$brand-one !default;

$mobile-dropdown-item-padding:			10px !default;
$mobile-dropdown-item-divider:			fade-out(white, .9) !default;
$mobile-dropdown-item-text-color:		white !default;
$mobile-dropdown-item-text-align:		center !default;
$mobile-dropdown-item-font-size:		$font-size-large !default;
$mobile-dropdown-item-font-weight:		normal !default;
$mobile-dropdown-item-active-bg:		darken($mobile-dropdown-bg, 5%) !default;

$expander-width: 54px !default;

%mainNavHover {
	color: $nav-item-hover-color;
	background: $nav-item-hover-bg;
	box-shadow: 0 0 0 99px $nav-item-hover-bg;


	@media (min-width: $screen-md) {
		border-bottom: 6px solid #236B84;
		padding-bottom: 28px;

		.sfHover &{
			border-bottom: 6px solid $brand-one;
		}

	}
}

#mainNav {
	position: static;
	display: none;
	top: 100%;
	margin: 0 ($container-padding * -1);
	width: auto;

	@media (min-width: $screen-sm) {
		margin-top: 50px;
	}

	@media (min-width: $screen-md) {
		position: static;
		display: block !important;
		margin: 0;
		top: auto;
		width: auto;
		margin-right: 20px;
	}
}

#nav {
	margin: 0;
	padding: 0;

	@media (min-width: $screen-md) {
		display: flex;
		justify-content: flex-end;
		width: 100%;
	}

	> li {
		min-width: 1px;
		border: none;
		padding: 10px 20px;
		overflow: hidden;
		text-overflow: ellipsis;

		@media (max-width: $screen-md - 1) {
			background-color: $nav-item-bg;
			border-bottom: solid 1px rgba(0,0,0,0.15) !important;
		}

		@media (min-width: $screen-md) {
			border-width: 1px 1px 1px 0;
			padding: 10px 16px 10px;

			&:first-child {
				border-width: 1px;
			}
		}

		&.sfHover {
			border-bottom: 0;
		}
	}

	&.nav--click { // click nav specific, see mainNav.js
	}

	&.nav--hover { // hover nav specific, see mainNav.js
		.dropdownViewAll {
			display: none;
		}
	}
}

a.mainNavItem {
	position: relative;
	display: inline-block;
	font-family: $nav-font-family;
	font-size: $nav-font-size;
	font-weight: $nav-font-weight;
	line-height: $nav-line-height;
	text-align: $nav-item-text-align;
	text-overflow: ellipsis;
	color: $nav-item-text-color;
	background: $nav-item-bg;
	overflow: hidden;

	@media (max-width: $screen-md - 1) {
		flex-grow: 1;
		width: calc(98% - #{$expander-width});

		.noDropdown & {
			width: 100%;
		}
	}

	@media (min-width: $screen-md){
		max-width: 230px;
		padding-bottom: 28px;
		border-bottom: 6px solid #236c8400;
	}

	&:focus,
	&:hover {
		text-decoration: none;
	}

	.current &,
	.sfHover & {
		@extend %mainNavHover;
	}

	#mainNav:hover .sfHover &,
	#mainNav:focus .sfHover & {
		@extend %mainNavHover;
	}
}
.topNavMobile {
	padding: 0!important;
	border-bottom: 0;

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		>li {
			border-bottom: solid 1px rgba(0,0,0,0.15);

			&:last-of-type {
				border-bottom: 0;
			}
		}
	}
}
.topNavMobile  a,
#browseAloudContainer a,
#browseAloudContainer button {
	@media (max-width: $screen-md - 1) {
		flex-grow: 1;
		display: block;
		padding: 10px 20px;
		position: relative;
		width: 100%;
		font-family: $nav-font-family;
		font-size: $nav-font-size;
		font-weight: $nav-font-weight;
		line-height: $nav-line-height;
		text-align: $nav-item-text-align;
		text-overflow: ellipsis;
		color: $nav-item-text-color;
		background: $nav-item-bg;
		overflow: hidden;

		.noDropdown & {
			width: 100%;
		}
	}
}

// combo box arrow for main nav
.mainNavItem_expander {
	position: relative;
	display: inline-block;
	width: $expander-width !important;
	text-align: center;
	border: 0;
	background-color: transparent;
	-moz-appearance: none;
	-ms-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	cursor: pointer;

	@media (min-width: $screen-md){
		display: none;
	}

	.icon {
		position: relative;
		display: block;
		width: 21px;
		height: 21px;
		margin: 0 auto;
		cursor: pointer;

		&::before,
		&::after {
			content: "";
			width: 16px;
			height: 2px;
			background: #000;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			transition: transform 350ms ease;
		}

		&::before {
			transform: rotate(180deg);
		}

		&::after {
			transform: rotate(90deg);
			transform-origin: center;
		}
	}

	&[aria-expanded="true"] {
		.icon {
			&::before,
			&::after {
				transform: rotate(0);
			}
		}
	}
}

// -----------------------------------------------------
// MAIN NAV DROP DOWN
// -----------------------------------------------------

#mainNav {
	.dropDownContainer {
		display: none;
		position: absolute;
		z-index: 1000;
		left: 5%;
		top: 92%;
		width: 90%;
		padding: $nav-dropdown-padding;
		border: 1px solid $nav-dropdown-border;
		border-top: none;
		border-radius: 0 0 $nav-dropdown-border-radius $nav-dropdown-border-radius;
		background: $nav-dropdown-bg;
		border-radius: 0 0 $nav-dropdown-border-radius $nav-dropdown-border-radius;
		text-align: left;
		margin-left: auto;
		filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.24));

		@media (max-width: $screen-md - 1) {
			flex: 1 1 auto;
			width: 100%;
			min-width: 0;
			border: 0;
			border-top: solid 1px #ccc;
			padding: 10px 0;
			position: static;
			background-color: rgba(255, 255, 255, 0);

			.dropdown {
				list-style: none;
				margin: 0;
				padding: 0;
				width: 100%;
				&:first-child {
					margin: 0;
				}
				li {
					display: block;
					width: 100%;
					background: none;
					&:hover, &:focus {
						background: #f5f5f5;
					}
					a {
						display: block;
						width: 100%;
						padding: 8px 16px;
						font-size: $nav-dropdown-item-font-size;
						font-weight: $nav-dropdown-item-font-weight;
						text-decoration: $nav-dropdown-item-decoration;
						color: $nav-dropdown-item-text-color;
						overflow: hidden;
						.contrast-mode & {
							color: black;
						}
					}
					&:last-child {
						border-bottom: none;
					}
				}
				&.dropDownRight {
					display: none;
				}
				&:nth-child(2) {
					li:last-child {
						border-bottom: none;
					}
				}
			}
		}
	}

	.dropdown {
		float: left;
		width: 100%/$nav-dropdown-col-count;
		padding: 0 $nav-dropdown-col-spacing;
		list-style: none;
		overflow: hidden;
		&:first-child {
			margin: 0;
		}
		li {
			float: left;
			width: 100%;
			background: none;
			a {
				display: block;
				width: 100%;
				color: $nav-dropdown-item-text-color;
				font-size: $nav-dropdown-item-font-size;
				font-weight: $nav-dropdown-item-font-weight;
				padding: $nav-dropdown-item-padding;
				text-decoration: $nav-dropdown-item-decoration;
				border-top: 1px solid $nav-dropdown-item-divider;
				overflow: hidden;
				text-overflow: ellipsis;
				&:hover, &:active, &:focus {
					text-decoration: $nav-dropdown-item-hover-decoration;
					background-color: $nav-dropdown-item-hover-bg;
				}
				&.viewMore {
					border: 1px solid $nav-dropdown-item-divider;
					padding: $nav-dropdown-item-padding;
					padding-left: 15px;
					padding-right: 15px;
					font-weight: 600;
					position: relative;

					&:before {
						@include icomoon();
						content: $icon-chevron-thick;
						position: absolute;
						font-size: em(20px);
						display: flex;
						align-items: center;
						top: 0;
						bottom: 0;
						right: 10px;
						color: #000;
						pointer-events: none;
					}
				}
			}
			&:first-child a {
				border: none;
			}
		}
	}

	.dropdownViewAll {
		display: block;
		margin: 0 $nav-dropdown-col-spacing .375em;
		text-align: right;

		@media (max-width: $screen-md - 1) {
			display: none;
		}

		a {
			padding: $nav-dropdown-item-padding;
			text-decoration: $nav-dropdown-item-decoration;
			color: #236B84;
			font-size: 18px;
			font-weight: 600;

			&:active,
			&:focus,
			&:hover {
				text-decoration: $nav-dropdown-item-hover-decoration;
				background-color: $nav-dropdown-item-hover-bg;
			}
		}
	}
}

// -----------------------------------------------------
// MAIN NAV DROP DOWN IMAGE RIGHT
// -----------------------------------------------------

#mainNav .dropdown a.dropDownRightInner {
	display: block;
	float: left;
	width: 100%;
	background: #fff;
	border: 1px solid $nav-dropdown-item-divider;
	position: relative;
	&:before {
		position: absolute;
		content: "";
		bottom: 2px;
		right: 2px;
		left: 2px;
		width: 100%;
		width: calc(100% - 4px);
		height: 100%;
		background: linear-gradient(to bottom, rgba(black,0) 75%,rgba(black,.75) 100%);
	}
	img {
		border: 2px solid #fff;
		width: 100%;
		height: auto;
	}
	.dropDownRightOverlay {
		display: block;
		width: 100%;
		color: #fff;
		font-size: 1.25em;
		line-height: 1.3em;
		font-weight: 500;
		position: absolute;
		right: 2px;
		bottom: 2px;
		left: 2px;
		padding: 8px 10px;
		text-align: center;
	}
	&:hover .dropDownRightOverlay, &:focus .dropDownRightOverlay{
		text-decoration: underline;
	}
}

