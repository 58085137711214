//  ======================================================
//  ATOZBAR.SCSS
//  A to Z Bar styles
//  ======================================================

#atozBar {
	display: none;
	padding: 0;
    font-size: $font-size-base;
	line-height: 60px;
	font-weight: bold;
    color: white;
    background: $brand-one;

    @media (min-width: $screen-sm) {
    	display: block;
    }

    @media (min-width: $screen-md) {
    	font-size: $font-size-h6;
    }

	ul, li {
		margin: 0;
		padding: 0;
	}

	ul {
		display: table;
		table-layout: fixed;
		padding-left: 120px;
		width: 100%;
		text-align: center;

		@media (min-width: $screen-md) {
			padding-left: 140px;
		}
	}

	li {
		display: table-cell;
		vertical-align: middle;
		list-style-type: none; /* this reset, with the :before, is for accessibility issues on Safari*/
		&:before {
		    content: "\200B"; /* add zero-width space */
		    position: absolute; /* addition */
		}

		a, a:link, a:visited {
			color: white;
		}

		a {
			display: block;
			height: 60px;
			font-weight: 600;
			text-align: center;
			text-decoration: none;
			text-transform: lowercase;
			overflow: hidden;

			&:focus, &:hover {
				text-decoration: none;
				background: lighten($brand-one, 5%);
			}
		}
	}

	span {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		max-width: 130px;
		color: white;
		overflow: hidden;
	}
}