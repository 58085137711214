

// @mixin contrast-mode($type, $value, $shift, $amount: 14%, $link: false) {
//   @if $shift==darken {
//       #{$type}: $value;
//       .contrast-mode & {
//           #{$type}: darken($value, $amount);
//           @if $link==link {
//               &:hover,
//               &:focus {
//                   #{$type}: darken($value, $amount);
//               }
//           }
//       }
//   }
//   @if $shift==lighten {
//       #{$type}: $value;
//       .contrast-mode & {
//           #{$type}: darken($value, $amount);
//       }
//   }
// }


// COLOR CONTRAST
@function color-luminance($color) {
    // Based on Formula: http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
    $rgba: red($color), green($color), blue($color);
    $rgba2: ();
  
    @for $i from 1 through 3 {
      $rgb: nth($rgba, $i);
      $rgb: $rgb / 255;
  
      $rgb: if($rgb < .03928, $rgb / 12.92, ch-pow(($rgb + .055) / 1.055, 2.4, 16));
  
      $rgba2: append($rgba2, $rgb);
    }
  
    @return .2126 * nth($rgba2, 1) + .7152 * nth($rgba2, 2) + 0.0722 * nth($rgba2, 3);
  }
  
  @function color-contrast-ratio($color1, $color2) {
    // Based on Formula: http://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef
    $luminance1: color-luminance($color1) + .05;
    $luminance2: color-luminance($color2) + .05;
    $ratio: $luminance1 / $luminance2;
  
    @if $luminance2 > $luminance1 {
      $ratio: 1 / $ratio;
    }
  
    $ratio: round($ratio * 10) / 10;
  
    @return $ratio;
  }
  
  @function color-contrast($base, $colors: (#fff, #000), $tolerance: 0) {
    $best: nth($colors, 1);
    $contrast: color-contrast-ratio($base, $best);
  
    @for $i from 2 through length($colors) {
      $currentColor: nth($colors, $i);
      $currentContrast: color-contrast-ratio($base, $currentColor);
      @if ($currentContrast - $contrast > $tolerance) {
        $best: $currentColor;
        $contrast: $currentContrast;
      }
    }
  
    @if ($contrast < 3) {
      @warn "Contrast ratio of #{$best} on #{$base} is bad. #{$contrast}";
    }
  
    @return $best;
  }
  
  // COLOR CONTRAST USAGE
  
  // color-contrast-ratio($color1, $color2) – returns the contrast ratio between two colors.
  // color-contrast($base, $colors: (#fff, #000), $tolerance: 0) – a background color and a list of front colors is compared.  The first front color with the best contrast ratio will be returned.  Typically only two colors will be compared.
  
  
  // MATH FUNCTIONS FOR COLOR CONTRAST 
  
  @function ch-gcd($a, $b) {
    // From: http://rosettacode.org/wiki/Greatest_common_divisor#JavaScript
    @if ($b != 0) {
      @return ch-gcd($b, $a % $b);
    } @else {
      @return abs($a);
    }
  }
  
  @function ch-pow($base, $exponent, $prec: 12) {
    // Handles decimal exponents by trying to convert them into a fraction and then use a nthRoot-algorithm for parts of the calculation
    @if (floor($exponent) != $exponent) {
      $prec2 : ch-pow(10, $prec);
      $exponent: round($exponent * $prec2);
      $denominator: ch-gcd($exponent, $prec2);
      @return ch-nth-root(ch-pow($base, $exponent / $denominator), $prec2 / $denominator, $prec);
    }
  
    $value: $base;
    @if $exponent > 1 {
      @for $i from 2 through $exponent {
        $value: $value * $base;
      }
    } @else if $exponent < 1 {
      @for $i from 0 through -$exponent {
        $value: $value / $base;
      }
    }
  
    @return $value;
  }
  
  @function ch-nth-root($num, $n: 2, $prec: 12) {
    // From: http://rosettacode.org/wiki/Nth_root#JavaScript
    $x: 1;
  
    @for $i from 0 through $prec {
      $x: 1 / $n * (($n - 1) * $x + ($num / ch-pow($x, $n - 1)));
    }
  
    @return $x;
  }
  