//  ======================================================
//  STYLES.CSS
//  master stylesheet included on every template.
//  ======================================================

@import 'functions';
@import 'css3-mixins';
@import 'mixins';
@import 'settings';

@import 'base';
@import 'sitecontent';
@import 'structure';

@import 'icomoon/style';
@import 'atozBar';
@import 'boxDates';
@import 'browseAloud';
@import 'contactAutoComplete';
@import 'footer';
@import 'header';
@import 'idLikeTo';
@import 'mainNav';
@import 'mobileNav';
@import 'newsTicker';
@import 'socialLinks';
//@import 'searchCludo';
@import 'searchGoogle';
@import 'topNav';
@import 'translateGoogle';
@import 'toast';
@import 'contrast';

@import 'devTweaks';
@import 'moduleOverrides';

//instagram
@import 'instagram';

#skipContentLink {
	&:active,
	&:hover,
	&:focus {
		background: $brand-two;
	}
}