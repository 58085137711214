//  ======================================================
//  MOBILENAV.SCSS
//  styles for main mobile nav
//  ======================================================

.mobileNav {
	display: flex;
	width: 110px;
	text-align: right;
	vertical-align: middle;

	@media (min-width: $screen-md) {
		display: none;
	}

	a,
	button {
		vertical-align: middle;
	}
}

.mobileNav a {
	display: inline-block;
	width: 45px;
	height: 45px;
	margin-left: 4px;
	font-size: em(30px);
	text-align: center;
	text-decoration: none;
	color: $text-color;
	background-color: $gray-background;
	border: 1px solid $gray-border;
	border-radius: $border-radius-small;
	overflow: hidden;
	
	&:focus,
	&:hover {
		color: $text-color;
	}
}

.mobileNav a img {
	width: 45px;
	height: 45px;
	margin: 0 auto;
	display: block;
}

.menuTrigger {
	@include burger;

	&[aria-expanded="true"] {
		@include burger-to-cross;
	}
}
