.socialLinks {
	margin: 1rem 0;
	width: 100%;
	text-align: center;

	@media (min-width: $screen-md) {
		text-align: center;
	}

	a {
		@include socialIcons('circle', 'brand', #fff);
		display: inline-block;
	}

	li {
		flex-shrink: 0;
		margin: 6px;
		line-height: 1em;
	}

	ul {
		display: inline-flex;
		align-items: center;
		flex-wrap: wrap;
		margin: 0 -0.1875em;
		padding: 0;
		list-style: none;
	}
}